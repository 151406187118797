import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES, GET_CHANNEL_URL } from "@/constants"

export default {
  name: "DomainChannels",
  data: () => ({
  }),
  props: {
    pChannels        : Array,
    pClientName      : String,
    pIsDomainArchived: Boolean,
    pChannelsCount   : Number,
    pAllChannels     : Array
  },
  computed: {
    headersForChannelsTable() {
      return getHeadersForTable(TABLE_NAMES.DOMAIN_CHANNELS, this.$t.bind(this))
    },
    footersForChannelsTable() {
      return getFootersForTable(TABLE_NAMES.DOMAIN_CHANNELS, this.$t.bind(this))
    },
    itemsForChannelsTable() {
      return this.pChannels.map(channel => {
        return {
          id              : channel.id,
          name            : channel.name,
          domainId        : channel.domainId,
          url             : GET_CHANNEL_URL(this.pClientName, channel.name),
          organisationCode: channel.organisationCode
        }
      })
    },
    numberOfChannelsEnabled() {
      return this.pAllChannels.filter(channel => channel.enabled).length
    },
    isAddChannelButtonDisabled() {
      return this.numberOfChannelsEnabled >= this.pChannelsCount
    }
  },
  methods: {
    clickRow(event, channel) {
      if (!event.target.className.includes("link")) {
        this.$emit("redirectToChannel", channel)
      }
    }
  }
}